.header {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    background-color: $white;

    &__wrap {
        position: relative;
    }

    &__heading {
        padding: 30px 0 20px;
        text-align: center;
        margin: 0 auto;
        width: 130px;
        position: relative;

        img {
            width: 130px;
            height: auto;
        }

        span {
            position: absolute;
            top: 15px;
            right: 0;
            font-size: px-to-rem(10px);
            line-height: px-to-rem(12px);
            font-weight: 600;
            color: $mainRed;
            text-decoration: none;
        }
    }

    &__menu {
        width: 20px;
        height: 16px;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 25px;

        span {
            position: absolute;
            display: block;
            background-color: $mainColorDark;
            height: 2px;
            border-radius: 4px;
            width: 100%;

            &:nth-child(1) {
                top: 0;
            }

            &:nth-child(1) {
                top: 50%;
                transform: translateY(-50%);
            }

            &:nth-child(3) {
                bottom: 0;
            }
        }
    }

    &__drawer {
        display: none;
        background-color: $white;
        position: fixed;
        top: 0;
        left: 0;
        right: 15%;
        bottom: -10%;
        z-index: 20;
        transform: translateX(-100%);
        transition-duration: 500ms;
        transition-property: transform;
        overflow-y: scroll;

        &--active {
            transform: translateX(0);
        }
    }

    &__drawer-logo {
        text-align: center;
        margin: 30px auto 0;
        position: relative;
        width: 100px;

        img {
            width: 100px;
            height: auto;
        }

        span {
            position: absolute;
            top: -12px;
            right: 0;
            font-size: px-to-rem(8px);
            line-height: px-to-rem(10px);
            font-weight: 600;
            color: $mainRed;
            text-decoration: none;
        }
    }

    &__drawer-btn {
        margin-top: 20px;
        text-align: center;

        a {
            background-color: $mainColorDark;
            color: $white;
            text-decoration: none;
            padding: 10px 25px;
            border-radius: 25px;
            font-size: px-to-rem(12px);
            line-height: px-to-rem(14px);
            font-weight: 600;
            letter-spacing: .8px;

            &:hover {
                background-color: $mainColor;
            }
        }
    }

    &__drawer-list {
        padding-top: 25px;

        p {
            font-weight: 500;
            padding-left: 25px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        li {
            border-bottom: 1px solid $mainGrey;

            &:first-of-type {
                border-top: 1px solid $mainGrey;
            }
        }

        .header__drawer-link {
            text-decoration: none;
            color: $mainColorDark;
            font-weight: 500;
            padding: 10px 10px 10px 40px;
            display: block;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);
            position: relative;

            &--active {
                font-weight: 600;
                background-color: $vipBg;
            }

            &:hover {
                color: $mainColor;
            }

            &::before {
                content: '';
                width: 6px;
                height: 6px;
                background-color: $mainRed;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 18px;
                border-radius: 50%;
            }
        }
    }

    &__drawer-bottom {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin: 10px 15px 50px;
    }

    &__drawer-ads {
        margin: 10px 5px;

        a {
            text-decoration: none;
            font-weight: 600;
            font-size: px-to-rem(12px);
            line-height: px-to-rem(14px);
            border: 1px solid $mainColor;
            padding: 5px 10px;
            color: $mainColor;
            letter-spacing: .8px;
            border-radius: 15px;

            &:hover {
                background-color: $mainColor;
                color: $white;
            }
        }

        .header__drawer-link--active {
            background-color: $mainColor;
            color: $white;
        }
    }

    &__btn {
        display: none;
    }

    &__btn-mobile {
        width: 25px;
        height: auto;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 25px;

        a {
            display: block;
        }

        img {
            vertical-align: bottom;
        }
    }
}

@media (min-width: 576px) {
    .header {
        &__drawer {
            right: 50%;
        }
    }
}

@media (min-width: 768px) {
    .header {
        &__drawer {
            right: 65%;
        }
    }
}

@media (min-width: 1024px) {
    .header {
        &__drawer {
            right: 70%;
        }

        &__btn {
            display: block;
            position: absolute;
            top: 50%;
            right: 25px;
            transform: translateY(-50%);

            a {
                background-color: $mainColorDark;
                color: $white;
                text-decoration: none;
                padding: 10px 25px;
                border-radius: 25px;
                font-size: px-to-rem(12px);
                line-height: px-to-rem(14px);
                font-weight: 600;
                letter-spacing: .8px;

                &:hover {
                    background-color: $mainColor;
                }
            }
        }

        &__btn-mobile {
            display: none;
        }
    }
}

@media (min-width: 1440px) {
    .header {
        &__drawer {
            right: 75%;
        }
    }
}