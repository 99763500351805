.accordions {
  display: flex;
  flex-direction: column;
  max-width: 650px;
  margin: 0 auto;
}

.accordion {
  width: 100%;

  &__links {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 15px 5px 15px;
  }

  &__link {
    color: $infoFieldGrey;
    text-decoration: none;
    background-color: $mainColorDark;
    color: $white;
    padding: 5px 15px;
    border-radius: 15px;
    font-size: px-to-rem(12px);
    line-height: px-to-rem(14px);
    margin: 0 5px 10px;

    &:hover {
      background-color: $mainColor;
    }

    &--active {
      background-color: $mainColor;
    }
  }
}

@media (min-width: 576px) {
  .accordions {
    flex-direction: row;
    justify-content: center;
  }

  .accordion {
    margin: 0 10px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

// Accordion Options

.ac {
  border-radius: 8px;
}

.ac .ac-trigger {
  font-size: px-to-rem(12px);
  line-height: px-to-rem(14px);
  font-family: 'Raleway', 'Arial', sans-serif;

  &:focus {
    color: $black;
  }
}
