.sidelinks {
    margin-top: 25px;

    &__item {
        border-radius: 5px;
        margin: 5px auto 20px;
        max-width: 280px;

        &--articles {
            border: 1px solid $mainRed;
            background-color: $white;
            box-shadow: 2px 2px 0 $mainRed;

            .sidelinks__link {
                color: $mainRed;
            }
        }

        &--history {
            border: 1px solid $mainColorDark;
            background-color: $white;
            box-shadow: 2px 2px 0 $mainColorDark;

            .sidelinks__link {
                color: $mainColorDark;
            }
        }

        &--blacklist {
            border: 1px solid $black;
            background-color: $white;
            box-shadow: 2px 2px 0 $black;

            .sidelinks__link {
                color: $black;
            }
        }
    }

    &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        text-decoration: none;
    }

    &__text {
        width: 100%;
        text-align: center;

        p {
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);
            margin: 10px 0;
            font-weight: 600;
        }
    }
}