.slider-main {
    text-align: center;
    margin-bottom: 35px;

    &__link {
        overflow: hidden;
        border-radius: 8px;
        display: block;
    }

    &__img {
        width: 100%;
        height: auto;
        border-radius: 8px;
        vertical-align: bottom;
        transition-duration: 500ms;
        transition-property: transform;

        &:hover {
            transform: scale(1.03);
        }
    }

    &__bullets {
        bottom: -10px;
        
        .glide__bullet {
            background-color: $mainGrey;
            border: none;
            width: 10px;
            height: 10px;
            transition: none;

            &--active {
                background-color: $activeSlide;
            }
        }
    }
}

@media (min-width: 768px) {
    .slider-main {
        &__link {
            max-width: 650px;
            margin: 0 auto;
        }

        &__img {
            max-width: 650px;
        }
    }
}