.breadcrumbs {
    border-radius: 8px;
    padding: 6px 10px;
    margin: 0 auto;
    max-width: 630px;

    &--single-ad {
        padding-left: 0;
        margin: 0;
        margin-bottom: 10px;
    }

    &--news {
        padding: 0 20px;
        margin: 12px 0 12px 10px;
    }

    a {
        color: $mainColorDark;
        font-weight: 600;
        font-size: px-to-rem(12px);
        line-height: px-to-rem(14px);
        margin: 0 5px;
        text-decoration: none;

        &:hover {
            color: $mainColor;
        }

        &:first-of-type {
            margin: 0 5px 0 0;
        }
    }

    span {
        color: $infoFieldGrey;
        font-weight: 400;
        font-size: px-to-rem(12px);
        line-height: px-to-rem(14px);
        cursor: default;
    }
}