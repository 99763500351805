.calculator {
    margin-top: 20px;

    &__wrap {
        background-color: $white;
        padding: 50px 25px 15px;
        border-radius: 8px;
        margin-bottom: 15px;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
        margin: 0 auto;
    }

    h2 {
        font-size: px-to-rem(18px);
        line-height: px-to-rem(20px);
        margin: 0 auto;
        text-align: center;
        background-color: $mainColor;
        color: $white;
        width: 80%;
        padding: 10px;
        border-radius: 8px;
        position: relative;
        top: 20px;
        cursor: default;
    }

    &__field {
        margin-bottom: 20px;
    }

    &__header {
        margin-bottom: 10px;

        p {
            font-weight: 500;
            color: $infoFieldGrey;
            cursor: default;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);
            text-align: center;
            margin: 0;
        }
    }

    &__range {
        output {
            display: block;
            text-align: center;
            cursor: default;
            border-radius: 4px;
            padding: 5px 0;
            color: $black;
            font-weight: 500;
            font-size: px-to-rem(20px);
            line-height: px-to-rem(22px);
        }
    }

    &__range-field {
        -webkit-appearance: none;
        width: 100%;
        background: transparent;

        &:focus {
            outline: none;
        }

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            background: $mainColorDark;
            margin-top: -7px;
            box-shadow: 1px 1px 2px rgba(#000, 0.5);
            cursor: pointer;
        }

        &::-moz-range-thumb {
            height: 16px;
            width: 16px;
            border-radius: 50%;
            background: $mainColorDark;
            box-shadow: 1px 1px 2px rgba(#000, 0.5);
            cursor: pointer;
            border: none;
        }

        &::-ms-thumb {
            height: 16px;
            width: 16px;
            border-radius: 50%;
            background: $mainColorDark;
            box-shadow: 1px 1px 2px rgba(#000, 0.5);
            cursor: pointer;
            border: none;
        }

        &::-webkit-slider-runnable-track {
            height: 2px;
            background: $mainColor;
            cursor: pointer;
        }

        &::-moz-range-track {
            height: 2px;
            background: $mainColor;
            cursor: pointer;
        }

        &::-ms-track {
            height: 2px;
            background: $mainColor;
            cursor: pointer;
        }
    }

    &__results-header {
        margin: 40px 0 30px;
        text-align: center;
        font-weight: 600;
        font-size: px-to-rem(18px);
        line-height: px-to-rem(20px);
        padding: 0;
    }

    &__results-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 15px 0;
    }

    &__results-info {
        font-weight: 500;
        color: $infoFieldGrey;
        cursor: default;
        font-size: px-to-rem(14px);
        line-height: px-to-rem(16px);
        margin: 0;
    }

    &__results-number {
        font-weight: 500;
        color: $white;
        padding: 5px 15px;
        cursor: default;
        font-size: px-to-rem(14px);
        line-height: px-to-rem(16px);
        text-align: center;
        margin: 0;
        background-color: $mainColorDark;
        border-radius: 15px;
        font-family: Arial, sans-serif;
    }
}

@media (min-width: 576px) {
    .calculator {
        h2 {
            width: 250px;
        }

        &__wrap {
            max-width: 350px;
        }
    }
}

@media (min-width: 1024px) {
    .calculator {
        h2 {
            width: 70%;
        }

        &__wrap {
            max-width: 100%;
        }
    }
}