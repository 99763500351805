.arrow {
    position: fixed;
    bottom: 7%;
    right: 6%;
    opacity: 0;
    z-index: -5;
    transition-duration: 500ms;
    transition-property: opacity;

    img {
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
}

@media (min-width: 1440px) {
    .arrow {
        left: 6%;
        right: auto;
    }
}