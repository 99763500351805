.promo {
    margin-top: 20px;

    &__slides {
        margin: 0;
    }

    &__slide {
        background-color: $white;
        border: 1px solid $mainGrey;
        border-radius: 8px;
        padding: 20px 15px;
        min-height: 526px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__company {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;

            img {
                width: 20px;
                height: 20px;
            }

            span {
                font-size: px-to-rem(14px);
                line-height: px-to-rem(16px);
                font-weight: 300;
                color: lighten($black, 20);
            }
        }

        &__title {
            min-height: 54px;

            p {
                margin: 0;
                font-size: px-to-rem(18px);
                line-height: px-to-rem(27px);
                font-weight: 500;
                color: $mainText;
            }
        }

        &__img {
            margin: 15px 0;

            img {
                width: 100%;
                height: auto;
            }
        }

        &__info {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin: 0 auto 10px;
            width: 95%;

            p {
                font-weight: 300;
                font-size: px-to-rem(13px);
                line-height: px-to-rem(15px);
                margin: 5px 0 5px 15px;
            }

            span {
                color: $infoFieldGrey;
                margin: 5px 0;
                font-weight: 300;
                font-size: px-to-rem(13px);
                line-height: px-to-rem(15px);
            }
        }

        &__contacts {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;

            a {
                font-size: px-to-rem(12px);
                line-height: px-to-rem(24px);
                color: $infoFieldGrey;
                text-decoration: none;
                margin: 0 3px;
            }

            span {
                font-size: px-to-rem(12px);
                line-height: px-to-rem(24px);
                color: $infoFieldGrey;
                margin: 0 3px;
            }
        }

        &__license {
            font-size: px-to-rem(12px);
            line-height: px-to-rem(24px);
            color: $infoFieldGrey;
            margin: 0 3px;
            margin-bottom: 10px;
        }

        &__btn {
            a {
                background-color: $mainColorDark;
                box-shadow: 2px 2px 0 darken($mainColorDark, 10%);
                color: $white;
                border-radius: 5px;
                padding: 10px 0;
                text-decoration: none;
                transition-property: background-color;
                transition-duration: 250ms;
                display: block;
                text-align: center;

                &:hover {
                    background-color: $mainColor;
                    box-shadow: 2px 2px 0 darken($mainColor, 10%);
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .promo {
        &__slide {
            &__info {
                min-height: 129px;
            }

            &__contacts {
                a {
                    font-size: px-to-rem(11px);
                }

                span {
                    font-size: px-to-rem(11px);
                }
            }

            &__license {
                font-size: px-to-rem(11px);
            }
        }
    }
}