.agreement {
    margin-top: 5px;
    background-color: $white;
    padding: 15px 25px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    margin-bottom: 15px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);

    h1, h2 {
        font-size: px-to-rem(20px);
        line-height: px-to-rem(30px);
        font-weight: 600;
        color: $black;
        text-align: center;
    }

    p {
        font-size: px-to-rem(14px);
        line-height: px-to-rem(21px);
        color: $mainText;
        text-indent: 14px;
        text-align: justify;
    }
}