.contacts {
    background-color: $white;
    border-radius: 5px;
    padding: 15px 20px;
    margin: 20px auto;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);

    &__img {
        text-align: center;
        margin-top: 10px;

        img {
            width: 200px;
            height: auto;
        }
    }

    &__text {

        p {
            font-size: px-to-rem(14px);
            line-height: px-to-rem(21px);
            font-weight: 500;
        }

        a {
            color: $mainColor;

            &:hover {
                color: $mainRed;
            }
        }

        ul {
            padding-left: 15px;
        }

        li {
            font-size: px-to-rem(14px);
            line-height: px-to-rem(21px);
            margin: 5px 0;
            text-align: justify;
            font-weight: 500;
        }

        span {
            font-weight: 600;
            color: $mainRed;
        }
    }

    &__p {
        margin: 15px 0;
        font-size: px-to-rem(14px);
        line-height: px-to-rem(21px);
        font-weight: 400;
        text-align: justify;
    }

    &__link {
        margin: 5px 0;
    }
}