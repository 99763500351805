.slider-sidebar {
    margin-top: 35px;

    h2 {
        font-size: px-to-rem(20px);
        line-height: px-to-rem(22px);
        margin: 0;
        text-align: center;
    }

    &__bullets {
        bottom: 5px;
        
        .glide__bullet {
            background-color: $mainGrey;
            border: none;
            width: 10px;
            height: 10px;
            transition: none;

            &--active {
                background-color: $activeSlide;
            }
        }
    }

    &__item {
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        margin: 0 auto 20px;
        position: relative;
        background-color: $white;
        width: 99%;
        height: 550px;
    }

    &__item-media {
        height: 300px;
        overflow: hidden;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        img {
            width: 100%;
            height: 300px;
            object-fit: cover;
            transition-duration: 500ms;
            transition-property: transform;
        }

        &:hover img {
            transform: scale(1.1);
        }
    }

    &__item-media-content {
        padding: 20px;
    }

    &__item-title {
        a {
            text-decoration: none;
            text-align: justify;
            color: $mainText;
            font-weight: 500;
            font-size: px-to-rem(18px);
            line-height: px-to-rem(20px);
            display: block;

            &:hover {
                color: $black;
                text-decoration: underline;
            }
        }
    }

    &__item-text {
        margin-bottom: 50px;

        p {
            font-size: px-to-rem(14px);
            line-height: px-to-rem(21px);
            text-align: justify;
        }
    }

    &__item-link {
        position: absolute;
        bottom: 20px;
        right: 15px;
        text-align: right;

        a {
            background: $mainColorDark;
            border-radius: 12px;
            color: $white;
            text-decoration: none;
            padding: 6px 18px;
            font-weight: 300;
            letter-spacing: .8px;
            font-size: px-to-rem(12px);

            &:hover {
                background-color: $mainColor;
            }
        }
    }

    &__item-date {
        position: absolute;
        bottom: 20px;
        left: 20px;
        font-size: .75rem;
        line-height: .875rem;
        color: $mainText;
        text-align: left;
    }
}