.success {
    &__info {
        background-color: $vipBg;
        border-radius: 5px;
        border: 1px solid $mainColorDark;
        color: $mainColorDark;
        font-weight: normal;
        margin: 15px auto 0;
        padding: 15px 10px;

        &--limit {
            background-color: $addInfoColor;
            border: 1px solid darken($addInfoColor, 20);
            color: lighten($black, 10);
        }
    }

    &__id {
        font-size: px-to-rem(20px);
        line-height: px-to-rem(30px);
        text-align: center;
        font-weight: 600;
        margin: 0;
    }

    &__congrats {
        text-align: center;
        font-size: px-to-rem(14px);
        line-height: px-to-rem(21px);
        margin: 0;
        margin-top: 10px;
        font-weight: 500;
    }
}