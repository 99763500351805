.warning {
    max-width: 600px;
    margin: 10px auto 0;

    p {
        font-size: px-to-rem(12px);
        line-height: px-to-rem(18px);
        margin: 0;
        text-align: center;

        span {
            font-weight: 600;
        }
    }
}