.history {
    margin-top: 25px;

    &__wrap {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__item {
        margin-bottom: 15px;

        &__link {

            img {
                width: 100%;
                height: auto;
                border-radius: 8px;
                box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
            }
        }
    }
}

@media (min-width: 576px) {
    .history {
        &__wrap {
            flex-direction: row;
            justify-content: space-between;
            padding: 0;
        }

        &__item {
            flex-basis: 49%;
        }
    }
}