.footer {
    margin-top: 25px;
    background-color: $footerBg;

    &__block {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    &__logo {
        margin: 30px 0;
        text-align: center;

        a {
            text-decoration: none;

            &:hover {
                img {
                    opacity: 1;
                }

                span {
                    opacity: 1;
                }
            }
        }

        img {
            width: 125px;
            height: auto;
            opacity: .7;
        }

        span {
            margin-top: 5px;
            display: block;
            color: $white;
            opacity: .7;
            font-size: px-to-rem(10px);
            line-height: px-to-rem(12px);
        }
    }

    &__social {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;

        a {
            margin: 0 7px;
            opacity: .7;

            &:hover {
                opacity: 1;
            }
        }

        img {
            width: 100px;
            height: auto;
            border-radius: 5px;
        }
    }

    &__links {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    &__link {
        margin-bottom: 10px;

        a {
            margin: 0 7px;
            color: $white;
            opacity: .7;
            text-decoration: none;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);
            font-weight: 500;
            letter-spacing: .5px;

            &:hover {
                opacity: 1;
            }
        }
    }

    &__under {
        margin-top: 15px;
        margin-bottom: 30px;

        p {
            margin: 0;
            color: $white;
            text-align: center;
            font-size: px-to-rem(12px);
            line-height: px-to-rem(18px);
            color: $footerGrey;

            a {
                color: $white;
                letter-spacing: .8px;
                font-weight: 600;
                opacity: .7;

                &:hover {
                    opacity: 1  ;
                }
            }
        }
    }
}

@media (min-width: 576px) {
    .footer {
        &__block {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        &__logo {
            margin: 30px 0;
        }

        &__social {
            margin: 30px 0;

            a {
                margin: 0 12px;
            }
        }

        &__links {
            flex-direction: row;
        }

        &__link:first-of-type {
            a {
                margin-left: 0;
            }
        }
    }
}

@media (min-width: 1200px) {
    .footer {
        &__block {
            margin: 0;
        }
    }
}