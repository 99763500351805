.categories {

    &__ads {
        text-align: right;
        padding-right: 30px;
        margin-bottom: 15px;

        a {
            text-decoration: none;
            color: $mainGrey;
            font-size: px-to-rem(12px);
            line-height: px-to-rem(14px);

            &:hover {
                color: $activeSlide;
            }
        }
    }

    &__item {
        background-color: $white;
        padding: 15px 10px 15px 35px;
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        margin-bottom: 10px;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);

        &::after {
            content: '';
            position: absolute;
        }
    }

    &__subheading {
        a {
            margin: 0;
            font-size: px-to-rem(16px);
            line-height: px-to-rem(18px);
            font-weight: 600;
            text-decoration: none;
            color: $mainColorDark;
            position: relative;

            &:hover {
                color: $mainRed;
            }

            &::before {
                content: '';
                position: absolute;
                background: url('../img/arrow-text.svg') no-repeat;
                background-size: 12px 12px;
                width: 12px;
                height: 12px;
                top: 50%;
                transform: translateY(-45%);
                left: -22px;
            }
        }

        &--active {
            a {
                color: $mainRed;
            }
        }
    }

    &__list {
        margin: 15px 0;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        li {
            margin: 7px 0;
        }
    }

    &__link {
        text-decoration: none;
        color: $mainColorDark;
        font-weight: 500;
        font-size: px-to-rem(14px);
        line-height: px-to-rem(16px);
        position: relative;

        &:hover {
            color: $mainColor;
        }

        &--active {
            font-weight: 600;

            &::before {
                content: '';
                width: 5px;
                height: 5px;
                background-color: $mainRed;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -19px;
                border-radius: 50%;
            }
        }
    }
}