.last_ads {
    margin-top: 35px;

    h2 {
        font-size: px-to-rem(20px);
        line-height: px-to-rem(22px);
        margin-bottom: 16px;
        text-align: center;
    }

    &__item {
        background-color: $white;
        padding: 15px 25px;
        border-radius: 8px;
        margin-bottom: 15px;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    }

    &__header {
        a {
            font-size: px-to-rem(18px);
            line-height: px-to-rem(27px);
            margin: 0;
            font-weight: 600;
            text-decoration: none;
            color: $mainColorDark;

            &:hover {
                color: $mainColor;
            }
        }
    }

    &__field {
        p {
            font-weight: 400;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);
            margin: 10px 0;
        }

        span {
            color: $infoFieldGrey;
        }
    }
}