.text {
    background-color: $white;
    border: 2px solid $mainColorDark;
    position: relative;
    margin: 25px 0;
    border-radius: 8px;

    &__header {
        h1 {
            font-size: px-to-rem(16px);
            line-height: px-to-rem(24px);
            font-weight: 600;
            color: $white;
            padding: 10px 20px;
            text-align: center;
            background-color: $mainColorDark;
            margin: 0;
        }
    }

    h2,
    h3 {
        font-size: px-to-rem(16px);
        line-height: px-to-rem(24px);
        padding: 5px 10px;
        font-weight: 500;
        color: $white;
        text-align: center;
        background-color: $mainColorDark;
        border-radius: 10px;
    }

    &__media {

        img {
            width: 100%;
            min-height: 250px;
            height: auto;
            border-radius: 5px;
            object-fit: cover;
        }

        &--top {
            padding: 0 20px;
        }

        &--article {
        	margin-bottom: 10px;
        }
    }

    &__content {
        padding: 20px;
        padding-bottom: 0;
        color: $black;

        p {
            font-size: px-to-rem(14px);
            line-height: px-to-rem(21px);
            color: $black;
            text-align: justify;

            &:first-of-type {
                margin-top: 0;
            }
        }

        p.cursive {
            font-style: italic;
        }

        a {
            font-weight: 500;
            color: $mainColorDark;
            text-decoration: underline;

            &:hover {
                color: $mainRed;
            }
        }

        span {
            font-weight: 600;
        }

        ul {
            padding-left: 20px;
            list-style: none;

            li {
                font-size: px-to-rem(14px);
                line-height: px-to-rem(24px);
                text-align: justify;
                position: relative;
                margin: 10px 0;

                &::before {
                    content: '';
                    background: url('../img/arrow-text.svg') no-repeat;
                    background-size: 12px 12px;
                    width: 12px;
                    height: 12px;
                    top: 6px;
                    left: -20px;
                    position: absolute;
                }
            }
        }
    }

    &__bottom {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        padding: 0 20px;

        span {
            font-size: px-to-rem(12px);
            line-height: px-to-rem(24px);
            color: $infoFieldGrey;
            position: relative;

            &:last-of-type {
                padding-left: 30px;
            }

            .text__eye-icon {
                width: 22px;
                height: 22px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -30px;
                right: auto;
            }
        }
    }
}

@media (min-width: 768px) {
    .text {
        &__header {
            h1 {

                font-size: px-to-rem(20px);
                line-height: px-to-rem(30px);
            }
        }

        h2,
        h3 {
            font-size: px-to-rem(18px);
            line-height: px-to-rem(27px);
        }

        &__content {

            p {
                font-size: px-to-rem(16px);
                line-height: px-to-rem(24px);
            }
        }
    }
}

@media (min-width: 1200px) {
    .text {
        &__header {

            h1 {
                width: 99.5%;
                transform: translateX(-2.3%);
                position: relative;
                margin-top: 20px;
                font-size: px-to-rem(20px);
                line-height: px-to-rem(30px);

                &::before {
                    content: '';
                    position: absolute;
                    left: 1px;
                    bottom: -16px;
                    border: 8px solid transparent;
                    border-top: 8px solid darken($mainColorDark, 15);
                    border-right: 8px solid darken($mainColorDark, 15);
                }

                &::after {
                    content: '';
                    position: absolute;
                    right: 1px;
                    bottom: -16px;
                    border: 8px solid transparent;
                    border-top: 8px solid darken($mainColorDark, 15);
                    border-left: 8px solid darken($mainColorDark, 15);
                }
            }
        }
    }
}