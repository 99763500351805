@keyframes pulse {
    0% {
        transform: scale(1);
    }

    5% {
        transform: scale(1.3);
    }

    10% {
        transform: scale(1);
    }

    15% {
        transform: scale(1.3);
    }

    20% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    }
}