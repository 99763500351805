.vip {
    &__item {
        background-color: $vipBg;
        border: 2px solid $mainColorDark;
        position: relative;
        padding: 20px 15px 0;
        margin-bottom: 30px;
        border-radius: 8px;

        &::after {
            content: '';
            background: url('../img/vip.svg') no-repeat;
            width: 40px;
            height: 40px;
            position: absolute;
            top: -20px;
            right: 25px;
            animation-name: pulse;
            animation-duration: 30s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
            animation-delay: 0.05s;
        }
    }

    &__header {
        margin-bottom: 15px;

        a {
            font-size: px-to-rem(18px);
            line-height: px-to-rem(27px);
            margin: 0;
            font-weight: 600;
            text-decoration: none;
            color: $mainColorDark;
            text-transform: uppercase;

            &:hover {
                color: $mainColor;
            }
        }
    }

    &__info-field {
        p {
            font-weight: 400;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);
            margin: 10px 0;
        }

        span {
            color: $infoFieldGrey;
        }
    }

    &__text-field {
        p {
            font-weight: 300;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);

            &:first-of-type {
                color: $infoFieldGrey;
                margin: 15px 0 5px;
            }

            &:last-of-type {
                line-height: px-to-rem(21px);
                text-align: justify;
                margin-top: 5px;
                overflow: hidden;
                margin-bottom: 10px;
            }
        }

        button {
            background-color: transparent;
            border: none;
            padding: 0;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);
            color: $mainColorDark;
            font-weight: 600;
            cursor: pointer;

            &:focus {
                outline: none;
            }
        }
    }

    &__collapse-more {
        display: none;
    }

    &__contacts {
        margin-top: 15px;
    }

    &__buttons-desktop {
        display: none;
    }

    &__buttons-mobile {
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        a {
            border: 2px solid $mainColorDark;
            border-radius: 5px;
            text-decoration: none;
            text-align: center;
            font-size: px-to-rem(12px);
            line-height: px-to-rem(14px);
            padding: 10px 0;
            color: $mainText;
            margin-bottom: 15px;
            font-weight: 600;
            letter-spacing: .5px;
        }

        .vip__email {
            position: relative;
            flex-basis: 100%;

            &::after {
                content: '';
                width: 18px;
                height: 18px;
                background: url('../img/email.svg') no-repeat;
                background-size: 18px 18px;
                position: absolute;
                top: 50%;
                transform: translateY(-45%);
                left: 10%;
            }
        }

        .vip__whatsapp {
            flex-basis: 100%;
            position: relative;

            &::after {
                content: '';
                width: 18px;
                height: 18px;
                background: url('../img/whatsapp.svg') no-repeat;
                background-size: 18px 18px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 10%;
            }
        }

        .vip__telegram {
            flex-basis: 100%;
            position: relative;

            &::after {
                content: '';
                width: 18px;
                height: 18px;
                background: url('../img/telegram.svg') no-repeat;
                background-size: 18px 18px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 10%;
            }
        }

        .vip__phone {
            flex-basis: 100%;
            position: relative;

            &::after {
                content: '';
                width: 18px;
                height: 18px;
                background: url('../img/phone.svg') no-repeat;
                background-size: 18px 18px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 10%;
            }
        }

        .vip__lead {
            flex-basis: 100%;
            background-color: $mainColorDark;
            color: $white;
            letter-spacing: 1px;

            &:hover {
                background-color: $mainColor;
                border-color: $mainColor;
            }
        }
    }

    &__form {
        display: none;
        width: 230px;
        position: absolute;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
        background-color: $white;
        padding: 25px;
        border-radius: 5px;
        z-index: 20;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        font-family: Arial, sans-serif;

        &--active {
            display: block;
        }
    }

    &__form-close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;

        img {
            width: 18px;
            height: 18px;
        }
    }

    &__form-info {
        margin-bottom: 15px;

        p {
            margin: 0;
            text-align: center;
            font-size: px-to-rem(12px);
            line-height: px-to-rem(18px);
            font-weight: 500;

            span {
                color: $mainColorDark;
                font-weight: 600;
            }
        }
    }

    &__form-field {
        border: 2px solid $mainColorDark;

        &--empty {
            border: 2px solid $modalGrey;
        }
    }

    &__form-input {
        margin-bottom: 15px;
        text-align: center;

        input {
            width: 88%;
            padding: 6px 15px;
            border-radius: 5px;
            color: $black;
            font-size: px-to-rem(12px);
            line-height: px-to-rem(14px);
            font-weight: 500;

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: $mainText;
                font-size: px-to-rem(12px);
                line-height: px-to-rem(14px);
            }
        }
    }

    &__form-btn {
        text-align: center;

        button {
            border: none;
            background-color: $mainColorDark;
            color: $white;
            padding: 5px 15px;
            letter-spacing: .5px;
            border-radius: 25px;
            cursor: pointer;

            &:hover {
                background-color: $mainColor;
            }

            &:focus {
                outline: none;
            }
        }

        &--disabled {
            button {
                background-color: $infoFieldGrey;
                pointer-events: none;
            }
        }
    }
}

@media (min-width: 768px) {
    .vip {
        &__buttons-mobile {
            display: none;
        }

        &__buttons-desktop {
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            a {
                border: 1px solid $mainText;
                text-decoration: none;
                text-align: center;
                font-size: px-to-rem(12px);
                line-height: px-to-rem(14px);
                padding: 8px;
                color: $black;
                margin-bottom: 15px;
                font-weight: 500;
                letter-spacing: .5px;
                margin-left: 2px;
                margin-right: 20px;

                &:last-of-type {
                    margin-right: 2px;
                }
            }

            .vip__email {
                position: relative;
                padding-right: 50px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                font-family: 'Arial', sans-serif;

                &:hover span {
                    background-color: $mainColor;
                }

                &::after {
                    content: 'Электронная почта';
                    position: absolute;
                    top: -6px;
                    left: 15px;
                    font-size: px-to-rem(9px);
                    line-height: px-to-rem(11px);
                    background-color: $vipBg;
                    padding: 0 5px;
                    color: $mainText;
                    font-family: 'Raleway', sans-serif;
                }

                span {
                    position: absolute;
                    top: -1px;
                    right: -1px;
                    background-color: $mainColorDark;
                    width: 32px;
                    height: 32px;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;

                    img {
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        top: 50%;
                        left: 45%;
                        transform: translate(-50%, -50%);
                    }
                }

                &--single-ad {
                    &::after {
                        background-color: $white;
                    }
                }
            }

            .vip__whatsapp {
                position: relative;
                padding-right: 50px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                font-family: 'Arial', sans-serif;

                &:hover span {
                    background-color: $mainColor;
                }

                &::after {
                    content: 'Whatsapp';
                    position: absolute;
                    top: -6px;
                    left: 15px;
                    font-size: px-to-rem(9px);
                    line-height: px-to-rem(11px);
                    background-color: $vipBg;
                    padding: 0 5px;
                    color: $mainText;
                    font-family: 'Raleway', sans-serif;
                }

                span {
                    position: absolute;
                    top: -1px;
                    right: -1px;
                    background-color: $mainColorDark;
                    width: 32px;
                    height: 32px;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;

                    img {
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                &--single-ad {
                    &::after {
                        background-color: $white;
                    }
                }
            }

            .vip__telegram {
                position: relative;
                padding-right: 50px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                font-family: 'Arial', sans-serif;

                &:hover span {
                    background-color: $mainColor;
                }

                &::after {
                    content: 'Telegram';
                    position: absolute;
                    top: -6px;
                    left: 15px;
                    font-size: px-to-rem(9px);
                    line-height: px-to-rem(11px);
                    background-color: $vipBg;
                    padding: 0 5px;
                    color: $mainText;
                    font-family: 'Raleway', sans-serif;
                }

                span {
                    position: absolute;
                    top: -1px;
                    right: -1px;
                    background-color: $mainColorDark;
                    width: 32px;
                    height: 32px;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;

                    img {
                        width: 18px;
                        height: 18px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-60%, -50%);
                    }
                }

                &--single-ad {
                    &::after {
                        background-color: $white;
                    }
                }
            }

            .vip__phone {
                position: relative;
                padding-right: 50px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                font-family: 'Arial', sans-serif;

                &:hover span {
                    background-color: $mainColor;
                }

                &::after {
                    content: 'Телефон';
                    position: absolute;
                    top: -6px;
                    left: 15px;
                    font-size: px-to-rem(9px);
                    line-height: px-to-rem(11px);
                    background-color: $vipBg;
                    padding: 0 5px;
                    color: $mainText;
                    font-family: 'Raleway', sans-serif;
                }

                span {
                    position: absolute;
                    top: -1px;
                    right: -1px;
                    background-color: $mainColorDark;
                    width: 32px;
                    height: 32px;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;

                    img {
                        width: 16px;
                        height: 16px;
                        position: absolute;
                        top: 50%;
                        left: 45%;
                        transform: translate(-50%, -50%);
                    }
                }

                &--single-ad {
                    &::after {
                        background-color: $white;
                    }
                }
            }

            .vip__lead {
                color: $white;
                border: none;
                background-color: $mainColorDark;
                box-shadow: 2px 2px 0 darken($mainColorDark, 10%);
                border-radius: 5px;
                padding: 8px 20px;

                &:hover {
                    background-color: $mainColor;
                    box-shadow: 2px 2px 0 darken($mainColor, 10%);
                }
            }
        }
    }
}