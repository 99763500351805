.title {
    margin: 10px 0;

    &--news {
        margin-top: 0;
    }

    &--search {
        margin-bottom: 25px;
    }

    h1,
    h2 {
        text-align: center;
        font-size: px-to-rem(20px);
        line-height: px-to-rem(30px);
        margin: 0;
        color: $black;
    }

    span {
        color: $mainColor;
    }
}