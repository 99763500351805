.ad {
    background-color: $white;
    border: 1px solid $mainGrey;
    border-radius: 8px;
    padding: 15px;
    padding-bottom: 0;
    margin-bottom: 30px;
    position: relative;
    margin-top: 25px;

    &__back {

        img {
            width: 20px;
            height: 20px;
            opacity: .5;
            transition-duration: 250ms;
            transition-property: opacity;

            &:hover {
                opacity: 1;
            }
        }
    }

    &__header {
        margin-bottom: 15px;

        h1 {
            font-size: px-to-rem(18px);
            line-height: px-to-rem(27px);
            margin: 0;
            font-weight: 600;
            color: $mainText;
            cursor: default;
            text-transform: uppercase;
        }
    }
}