.archive {
    margin-top: 35px;

    h2 {
        font-size: px-to-rem(20px);
        line-height: px-to-rem(22px);
        margin: 0;
        text-align: center;
    }

    &__group {
        margin-top: 15px;

        h3 {
            font-size: px-to-rem(18px);
            line-height: px-to-rem(20px);
            margin: 0;
            text-align: center;
            color: $mainText;
            font-weight: 500;
            margin-bottom: 20px;
        }

        ul {
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0px;
        }

        li {
            margin: 0 5px 25px 5px;

            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }

            a {
                color: $white;
                text-decoration: none;
                background-color: $mainColorDark;
                padding: 7px 15px;
                border-radius: 25px;
                font-size: px-to-rem(12px);
                line-height: px-to-rem(14px);
                transition-property: background-color;
                transition-duration: 250ms;
                font-weight: 500;
                letter-spacing: .8px;

                &:hover {
                    background-color: $mainColor;
                }
            }
        }
    }
}

@media (min-width: 1024px) {
    .archive {
        &__group {
            margin: 15px 25px 0;
        }
    }
}