.pagination {
    width: 70%;
    margin: 0 auto 30px;

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        list-style: none;
        padding: 0;
    }

    &__item {
        margin: 0 3px;
        position: relative;

        &--active {

            &::after {
                content: '';
                width: 25px;
                height: 25px;
                position: absolute;
                background-color: $mainColorDark;
                opacity: .8;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                border-radius: 50%;
            }

            a {
                color: $white;
            }
        }

        &:hover {
            &::after {
                content: '';
                width: 25px;
                height: 25px;
                position: absolute;
                background-color: $mainColorDark;
                opacity: .8;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                border-radius: 50%;
            }

            a {
                color: $white;
            }
        }
    }

    &__link {
        text-decoration: none;
        color: $black;
        position: relative;
        z-index: 2;
        font-size: px-to-rem(12px);
        line-height: px-to-rem(24px);
        display: block;
        padding: 0 10px;
    }
}