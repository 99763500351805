.bottom {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 10px;

  &--eye {
    span {
      &:nth-of-type(4) {
        padding-left: 30px;
      }
    }
  }

  &--vip-eye {
    span {
      &:nth-of-type(3) {
        padding-left: 30px;
      }
    }
  }

  span {
    font-size: px-to-rem(12px);
    line-height: px-to-rem(18px);
    color: $infoFieldGrey;
    margin-right: 15px;
    margin-bottom: 5px;
    position: relative;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__btn {
    flex-basis: 100%;

    a {
      border: 2px solid $mainColorDark;
      border-radius: 5px;
      text-decoration: none;
      text-align: center;
      font-size: px-to-rem(12px);
      line-height: px-to-rem(14px);
      padding: 5px 15px;
      color: $mainColorDark;
      margin-bottom: 15px;
      font-weight: 500;
      letter-spacing: 0.5px;
      display: block;

      &:hover {
        background-color: $mainColorDark;
        border-color: $mainColorDark;
        color: $white;
      }
    }
  }

  &__eye {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: auto;
  }

  &__abuse {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-70%);
    left: 0;
    filter: grayscale(1);
    opacity: 0.7;

    &:hover {
      opacity: 1;
      filter: grayscale(0);
    }
  }
}

@media (min-width: 576px) {
  .bottom {
    &__btn {
      flex-basis: auto;
      margin-right: 15px;
    }
  }
}
