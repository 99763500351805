.search {
    margin-bottom: 15px;

    &__wrap {
        padding-top: 20px;
        position: relative;
        z-index: 5;
    }

    &__form {
        position: relative;
    }

    &__field {
        border: none;
        width: 100%;
        border-radius: 8px;
        padding: 8px 0 8px 32px;
        font-size: px-to-rem(14px);
        line-height: px-to-rem(16px);
        color: darken($mainGrey, 30);

        &:focus {
            outline: none;
        }
    }

    ::-webkit-input-placeholder {
        color: $mainGrey;
        font-size: px-to-rem(14px);
        line-height: px-to-rem(16px);
    }

    ::-webkit-search-cancel-button {
        display: none;
    }

    &__btn {
        position: absolute;
        background: url('../img/search-icon.svg') no-repeat;
        background-size: contain;
        width: 14px;
        height: 14px;
        right: 0;
        border: none;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        cursor: pointer;
    }

    &__results {
        margin: 0;
        background-color: $white;
        position: absolute;
        left: 25px;
        right: 25px;
        z-index: 50;
        padding: 0;
        list-style: none;
        max-height: 210px;
        overflow-y: scroll;
        border: 1px solid $bodyBg;

        a {
            font-size: px-to-rem(14px);
            line-height: px-to-rem(14px);
            font-weight: 500;
            color: $activeSlide;
            text-decoration: none;
            display: block;
            padding: 10px 23px;
            text-transform: lowercase;
            border-bottom: 1px solid $vipBg;

            &:hover {
                background-color: $modalGrey;
            }
        }

        .search__vip {
            padding-left: 50px;
            position: relative;
            font-weight: 600;

            &::after {
                content: '';
                background: url('../img/vip.svg') no-repeat;
                width: 20px;
                height: 20px;
                background-size: 20px 20px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 20px;
            }
        }
    }

    &__colored {
        color: $mainColor;
    }
}