.abuse {
    margin-bottom: 25px;
    
    &__star {
        color: $mainRed;
        font-size: px-to-rem(18px);
    }

    &__field {
        margin-top: 10px;

        label {
            font-size: px-to-rem(12px);
            line-height: px-to-rem(14px);
            font-weight: 600;
            color: $black;
        }

        textarea {
            margin-top: 10px;
            height: 250px;
            resize: none;
            width: 96%;
            padding: 7px 2% 7px 2%;
            font-size: px-to-rem(12px);
            line-height: px-to-rem(18px);
            border: 1px solid $infoFieldGrey;
            border-radius: 5px;
            font-weight: 500;

            &:focus {
                outline: none;
            }
        }

        input {
            margin-top: 10px;
            width: 96%;
            padding: 7px 2% 7px 2%;
            border-radius: 5px;
            color: $black;
            font-size: px-to-rem(12px);
            line-height: px-to-rem(14px);
            font-weight: 500;

            &:focus {
                outline: none;
            }
        }

        .abuse__input {
            border: 1px solid $mainColor;

            &--empty {
                border: 1px solid $infoFieldGrey;
            }
        }
    }

    &__btn {
        margin-top: 10px;
        text-align: center;
        width: 100%;

        button {
            color: $white;
            padding: 10px 25px;
            border-radius: 5px;
            border: none;
            font-size: px-to-rem(12px);
            line-height: px-to-rem(14px);
            cursor: pointer;
            font-weight: 600;
            letter-spacing: .8px;
            background-color: $mainColorDark;
            box-shadow: 2px 2px 0 darken($mainColorDark, 10%);

            &:focus {
                outline: none;
            }

            &:hover {
                background-color: $mainColor;
                box-shadow: 2px 2px 0 darken($mainColor, 10%);
            }
        }

        &--disabled {

            button {
                background-color: $infoFieldGrey;
                pointer-events: none;
                box-shadow: none;
            }
        }
    }
}