.cities {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &__btn {
        color: $mainColorDark;
        font-weight: 500;
        background-color: $white;
        text-decoration: none;
        border: 1px solid $mainColorDark;
        padding: 5px 10px;
        margin: 5px;
        border-radius: 5px;
        font-size: px-to-rem(11px);
        line-height: px-to-rem(13px);
    
        &:hover {
            background-color: $mainColor;
            border: 1px solid $mainColor;
            color: $white;
        }
    }
}