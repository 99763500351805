.normal {
    &__item {
        background-color: $white;
        border: 1px solid $mainGrey;
        border-radius: 8px;
        position: relative;
        padding: 20px 15px 0;
        margin-bottom: 30px;

        &:last-of-type {
            margin-bottom: 20px;
        }
    }

    &__header {
        margin-bottom: 15px;

        a {
            font-size: px-to-rem(18px);
            line-height: px-to-rem(27px);
            margin: 0;
            font-weight: 600;
            text-decoration: none;
            color: $mainText;
            text-transform: uppercase;

            &:hover {
                color: $black;
            }
        }
    }

    &__info-field {
        p {
            font-weight: 400;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);
            margin: 10px 0;
        }

        span {
            color: $infoFieldGrey;
        }
    }

    &__text-field {
        p {
            font-weight: 300;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);

            &:first-of-type {
                color: $infoFieldGrey;
                margin: 15px 0 5px;
            }

            &:last-of-type {
                line-height: px-to-rem(21px);
                text-align: justify;
                margin-top: 5px;
                overflow: hidden;
                margin-bottom: 10px;
            }
        }

        button {
            background-color: transparent;
            border: none;
            padding: 0;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);
            color: $mainColorDark;
            font-weight: 600;
            cursor: pointer;

            &:focus {
                outline: none;
            }
        }
    }
}