.content {
    &__grid {
        display: flex;
        flex-direction: column;
    }

    &__vip {
        margin-top: 25px;
    }

    &__normal {
        margin-top: 25px;
    }
}

@media (min-width: 1024px) {
    .content {
        &__grid {
            flex-direction: row;
        }

        &__left {
            width: 64%;
        }

        &__right {
            margin-left: auto;
            width: 30%;
        }
    }
}