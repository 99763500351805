.addinfo {
    max-width: 600px;
    background-color: $addInfoColor;
    border: 1px solid darken($addInfoColor, 20);
    padding: 15px 20px;
    color: lighten($black, 10);
    margin: 25px auto 0;
    font-weight: 300;
    text-align: justify;
    font-size: px-to-rem(14px);
    line-height: px-to-rem(21px);
    border-radius: 10px;

    p {
        margin-top: 0;
        font-weight: 500;
    }

    &__list {
        ol {
            padding: 0 15px;
            margin: 0;
        }

        li {
            margin: 5px 0;
        }

        span {
            font-weight: 500;
        }
    }

    &__bold {
        font-weight: 500;
    }
}